import { defineStore } from 'pinia';
import { RouteRecordRaw } from 'vue-router';
import router, { asyncRouterList } from '@/router';
import { store } from '@/store';

function filterPermissionsRouters(routes: Array<RouteRecordRaw>, roles: Array<unknown>) {
  const res = [];
  const removeRoutes = [];
  routes.forEach((route) => {
    const children = [];
    route.children?.forEach((childRouter) => {
      const roleCode = childRouter.meta?.roleCode || childRouter.name;
      if (roles.indexOf(roleCode) !== -1) {
        children.push(childRouter);
      } else {
        removeRoutes.push(childRouter);
      }
    });
    if (children.length > 0) {
      route.children = children;
      res.push(route);
    }
  });
  return { accessedRouters: res, removeRoutes };
}

export const usePermissionStore = defineStore('permission', {
  state: () => ({
    whiteListRouters: ['/login'], //白名单
    routers: [], //菜单需要加载的路由 initRoutes来初始化
    removeRoutes: [], //filterPermissionsRouters返回的需要删除的路由
  }),
  actions: {
    // 根据角色信息去路由表里面筛选
    async initRoutes(roles: Array<unknown>) {
      let accessedRouters = [];

      //   Log('initRoutes', roles);

      let removeRoutes = [];

      //   Log('asyncRouterList', asyncRouterList);

      const res = [];

      asyncRouterList.forEach((route) => {
        const children = [];
        route.children?.forEach((childRouter) => {
          const roleCode = childRouter.meta?.roleCode || childRouter.name;
          if (roles.indexOf(roleCode) !== -1) {
            children.push(childRouter);
          } else {
            removeRoutes.push(childRouter);
          }
        });
        if (children.length > 0) {
          route.children = children;
          res.push(route);
        }
      });
      //   Log('res', res);

      this.routers = res;

      // special token
      //   if (roles.includes('all')) {
      //     //包含所有路由
      //     accessedRouters = asyncRouterList;
      //   } else {
      //     const res = filterPermissionsRouters(asyncRouterList, roles);
      //     accessedRouters = res.accessedRouters;
      //     removeRoutes = res.removeRoutes;
      //   }

      //   this.routers = accessedRouters;
      //   this.removeRoutes = removeRoutes;

      //   removeRoutes.forEach((item: RouteRecordRaw) => {
      //     if (router.hasRoute(item.name)) {
      //       router.removeRoute(item.name);
      //     }
      //   });
    },
    async restore() {
      //把删除的路由重新添加回来
      this.removeRoutes.forEach((item: RouteRecordRaw) => {
        router.addRoute(item);
      });
    },
  },
});

export function getPermissionStore() {
  return usePermissionStore(store);
}
