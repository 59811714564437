<template>
  <router-view :class="[mode]" :key="$route.fullPath" />
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { useSettingStore } from '@/store';
import PDFView from './components/pdfPreview.vue';

const store = useSettingStore();

const mode = computed(() => {
  return store.displayMode;
});
</script>
<style lang="less" scoped>
@import '@/style/variables.less';

#nprogress .bar {
  background: var(--td-brand-color) !important;
}
</style>
