<template>
  <!-- <t-breadcrumb separator="/" :maxItemWidth="'200'" style="margin-bottom: 16px">
    <t-breadcrumbItem>{{ detailModels.bread.value.topName }}</t-breadcrumbItem>
    <t-breadcrumbItem @click="quitDetail">{{ detailModels.bread.value.middleName }}</t-breadcrumbItem>
    <t-breadcrumbItem>{{ detailModels.bread.value.detailName }}</t-breadcrumbItem>
  </t-breadcrumb> -->
  <t-card :loading="detailModels.detailLoading.value" :bordered="false">
    <div v-show="!detailModels.detailLoading.value">
      <Information v-bind="$attrs"></Information>
    </div>
  </t-card>
</template>

<script setup lang="ts">
import Information from './information.vue';

//详情页数据
import { DetailItemType, DetailModel } from '@/store';
import { commonStore } from '@/store';
const commonS = commonStore();
const detailModels = storeToRefs(commonS);

//返回
// const detailRouter = useRouter();
// const quitDetail = () => {
//   detailRouter.back();
// };
</script>

<style lang="less" scoped></style>
