<template>
  <t-space>
    <div>
      <t-upload
        ref="uploadRef"
        v-model="defaultFiles"
        :theme="theme"
        accept="image/*"
        :before-upload="beforeUpload"
        :showUploadProgress="false"
        :request-method="requestMethod"
        @remove="onRemove"
      ></t-upload>
      <t-button v-show="isUploading" @click="cancelUpoload" theme="default" variant="base">取消上传</t-button>
    </div>

    <div
      style="height: 110px; white-space: pre-line; display: flex; justify-content: center; align-items: center"
      class="e-warning"
    >
      {{ description }}
    </div>
  </t-space>
</template>

<script setup lang="ts">
import { uploadServer } from '@/api/upload';
import { MessagePlugin } from 'tdesign-vue-next';

import { commonStore } from '@/store';

const commonS = commonStore();
const { clientUrl, containerSas } = storeToRefs(commonS);

const uploadRef = ref();
const props = defineProps({
  files: {
    default: [],
    type: Array,
  },
  theme: {
    default: 'image',
    type: String,
  },
  //   图片限制大小单位 M
  limitSize: {
    default: 10,
    type: Number,
  },
  //   图片限制格式
  limitTypes: {
    default: ['png', 'jpg', 'jpeg'],
    type: Array,
  },
  description: {
    default: '',
    type: String,
  },
});

const defaultFiles = ref([]);
watchEffect(() => {
  defaultFiles.value = props.files;
});

// 定义v-model属性 更新父组件传过来的值
const myUploadEmits = defineEmits(['update:files', 'onFilesChange']);

const isUploading = ref(false); //是否正在上传
const myUploadPhotoName = ref(''); //上传文件设置的文件名，用来取消上传，UUID名有的地方拿不到
const cancelUpoload = () => {
  uploadServer.cancelUpload(myUploadPhotoName.value);
  isUploading.value = false;
};

// file 为等待上传的文件信息，用于提供给上传接口。file.raw 表示原始文件
// { status: 'success' | 'fail'; error?: string; response: { url?: string; files?: UploadFile[]; [key: string]: any } }

const requestMethod = async (file) => {
  isUploading.value = true; //开始上传
  const uuid = GetUUID();
  const hz = file.name.substring(file.name.lastIndexOf('.') + 1);
  file.name = ''; //为了不展示图片的名称
  myUploadPhotoName.value = file?.raw?.name;

  try {
    let uploadResult = await uploadServer.upload(
      clientUrl.value,
      `${uuid}.${hz}`,
      containerSas.value,
      file,
      (progress) => {},
    );
    if (uploadResult == true) {
      return new Promise((resolve) => {
        isUploading.value = false;
        myUploadEmits('update:files', [{ url: `${clientUrl.value}/${uuid}.${hz}` }]);
        myUploadEmits('onFilesChange');
        resolve({ status: 'success', response: { url: `${clientUrl.value}/${uuid}.${hz}` } });
      });
    } else {
      return new Promise((resolve) => {
        isUploading.value = false;

        resolve({ status: 'fail', error: '上传失败' });
      });
    }
  } catch (error) {
    isUploading.value = false;
    return new Promise((resolve) => {
      isUploading.value = false;
      resolve({ status: 'fail', error: '上传失败' });
    });
  }
};

// 上传之前对格式和大小进行判断
const beforeUpload = (file) => {
  if (file.size > props.limitSize * 1024 * 1024) {
    MessagePlugin.warning(`上传的图片不能大于${props.limitSize}M`);
    return false;
  }
  const hz = file.name.substring(file.name.lastIndexOf('.') + 1);
  if (props.limitTypes.indexOf(hz) < 0) {
    MessagePlugin.warning(`格式要求为：${props.limitTypes.join('、')}`);
    return false;
  }
  return true;
};

const onRemove = () => {
  myUploadEmits('update:files', []);
  myUploadEmits('onFilesChange');
};
</script>

<style lang="less" scoped></style>
