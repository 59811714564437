import axios from 'axios';
import { MessagePlugin } from 'tdesign-vue-next';
import { TOKEN_NAME } from '@/config/global';

import router from '@/router';

// const productionUrl = 'https://el-admin.modelcastle.com';

// 测试环境需要打开注释;
// axios.defaults.baseURL = productionUrl;

// 本地环境需要打开注释;
// axios.defaults.baseURL = '/api';

// 响应拦截器
axios.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么

    // Log('interceptors', response);
    // 登录已过期或未登录
    if (response.data.code == 401) {
      //   Log('interceptors 401');
      MessagePlugin.error('登录已失效');
      router.replace('/login');
      return Promise.reject(response?.data?.msg);
    }
    if (response.data.code == 403) {
      MessagePlugin.error('没有访问权限');
    }

    return response;
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    MessagePlugin.error(error?.message);
    return Promise.reject(error);
  },
);

const myAxios = async (body: object, contentType?: string) => {
  // 获取accessToken
  const accessToken = '';
  // await commonFun.getStorageFun("accessToken").then((res: any) => {
  //     accessToken = res;
  // });
  let options: any = {
    method: 'get',
    url: '',
    timeout: 20000, // 超时时间,
    waittime: 0,
    waittext: '加载中',
    fail: true, // 失败是否弹窗,
    headers: {
      // 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',

      // 'Content-Type': 'application/json;charset=utf-8',
      'Content-Type': contentType || 'application/json;charset=utf-8',
      Authorization: window.localStorage.getItem(TOKEN_NAME),
      Fromsite: 2,
    },
    data: null,
    loading: false,
    params: null,
    failBack: null,
    mobile: false,
    otherRequestMethods: false,
  };
  // eslint-disable-next-line eqeqeq
  if (accessToken && accessToken != 'null') {
    options.headers.Authorization = accessToken;
  }
  options = Object.assign(options, body);

  if (options.method.toLocaleUpperCase() === 'GET' || options.method.toLocaleUpperCase() === 'DELETE') {
    // 一般来说get和delete是param 问号传参

    (options as any).params = (options as any).data;
    (options as any).data = null;
  } else {
    // 如果post put 是 param传参
    // eslint-disable-next-line no-lonely-if
    if (options.otherRequestMethods) {
      (options as any).params = (options as any).data;
      (options as any).data = null;
      // options.headers = { 'Content-Type': 'application/json;charset=utf-8' }; // JSON格式请求
    } else {
      // (options as any).data = (options as any).data instanceof FormData ? options.data : qs.stringify((options as any).data);
      (options as any).data = (options as any).data instanceof FormData ? options.data : (options as any).data;
      (options as any).params = null;
    }
  }
  return new Promise((resolve, reject) => {
    (axios as any)({
      url: options.url,
      method: options.method,
      timeout: options.timeout,
      headers: options.headers,
      data: options.data,
      params: options.params,
    })
      .then((res: any) => {
        if (res.data.code) {
          resolve(res.data);
        } else {
          if ((options as any).failBack) {
            (options as any).failBack(res.data);
          }
          reject(res.data);
        }
      })
      .catch((err: any) => {
        axiosFailFun(err.response);
        reject(err.response);
      });
  });
};

const axiosFailFun = (error: any) => {
  if (error && error.status) {
    switch (error.status) {
      case 400:
        error.message = '请求错误(400)';
        break;
      case 401:
        error.message = '未授权，请重新登录(401)';
        break;
      case 403:
        error.message = '拒绝访问(403)';
        break;
      case 404:
        error.message = '请求出错(404)';
        break;
      case 408:
        error.message = '请求超时(408)';
        break;
      case 500:
        error.message = '服务器错误(500)';
        break;
      case 501:
        error.message = '服务未实现(501)';
        break;
      case 502:
        error.message = '网络错误(502)';
        break;
      case 503:
        error.message = '服务不可用(503)';
        break;
      case 504:
        error.message = '网络超时(504)';
        break;
      case 505:
        error.message = 'HTTP版本不受支持(505)';
        break;
      default:
        error.message = `连接出错(${error.response.status})!`;
    }
  }
};

export default myAxios;
