import { createPinia } from 'pinia';
import { createPersistedState } from 'pinia-plugin-persistedstate';

const store = createPinia();
store.use(createPersistedState());

export { store };

export * from './modules/notification';
export * from './modules/permission';
export * from './modules/user';
export * from './modules/setting';
export * from './modules/tabs-router';
export * from './modules/course';
export * from './modules/acivity';
export * from './modules/routerData';
export * from './modules/messages';
export * from './modules/common';
export * from './modules/marketing';

export default store;
