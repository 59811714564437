<template>
  <div :class="sideNavCls">
    <t-menu
      :class="menuCls"
      theme="dark"
      :value="active"
      :collapsed="collapsed"
      :default-expanded="defaultExpanded"
      @change="changeHandler"
    >
      <template #logo>
        <!--                <span v-if="showLogo" :class="`${prefix}-side-nav-logo-wrapper`" @click="goHome">-->
        <!--                  <component :is="getLogo()" :class="`${prefix}-side-nav-logo-${collapsed ? 't' : 'tdesign'}-logo`" />-->
        <!--                </span>-->
        <span :class="`${prefix}-side-nav-logo-wrapper`" @click="goHome">
          <img
            :src="collapsed == true ? LogoOne : LogoTwo"
            :style="collapsed == false ? { height: '62px' } : { height: '32px', width: '32px' }"
            :class="`${prefix}-side-nav-logo-${collapsed ? 't' : 'tdesign'}-logo`"
          />
        </span>
      </template>

      <menu-content :nav-data="menu" />
      <template #operations>
        <!-- <span class="version-container"> Version: 2023-3-14</span> -->
      </template>
    </t-menu>
    <div :class="`${prefix}-side-nav-placeholder${collapsed ? '-hidden' : ''}`"></div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, PropType } from 'vue';
import { useRouter } from 'vue-router';
import union from 'lodash/union';

import { useSettingStore } from '@/store';
import { prefix } from '@/config/global';
import pgk from '../../../package.json';
import { MenuRoute } from '@/types/interface';
import { getActive, getRoutesExpanded } from '@/router';

import AssetLogo from '@/assets/assets-t-logo.svg?component';
import AssetLogoFull from '@/assets/assets-logo-full.svg?component';
import MenuContent from './MenuContent.vue';

import LogoOne from '@/assets/assets-logo.png';
import LogoTwo from '@/assets/assets-logo-transparent.png';

import { commonStore } from '@/store';
const commonS = commonStore();
const { commonCurrentPage } = storeToRefs(commonS);

const MIN_POINT = 992 - 1;

const props = defineProps({
  menu: {
    type: Array as PropType<MenuRoute[]>,
    default: () => [],
  },
  showLogo: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  isFixed: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  layout: {
    type: String as PropType<string>,
    default: '',
  },
  headerHeight: {
    type: String as PropType<string>,
    default: '64px',
  },
  theme: {
    type: String as PropType<string>,
    default: 'dark',
  },
  isCompact: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
});

const collapsed = computed(() => useSettingStore().isSidebarCompact);

const active = computed(() => getActive());

const defaultExpanded = computed(() => {
  const path = getActive();
  const parentPath = path.substring(0, path.lastIndexOf('/'));
  const expanded = getRoutesExpanded();
  return union(expanded, parentPath === '' ? [] : [parentPath]);
});

const sideNavCls = computed(() => {
  const { isCompact } = props;
  return [
    `${prefix}-sidebar-layout`,
    {
      [`${prefix}-sidebar-compact`]: isCompact,
    },
  ];
});

const menuCls = computed(() => {
  const { showLogo, isFixed, layout } = props;
  return [
    `${prefix}-side-nav`,
    {
      [`${prefix}-side-nav-no-logo`]: !showLogo,
      [`${prefix}-side-nav-no-fixed`]: !isFixed,
      [`${prefix}-side-nav-mix-fixed`]: layout === 'mix' && isFixed,
    },
  ];
});

const router = useRouter();
const settingStore = useSettingStore();

const autoCollapsed = () => {
  const isCompact = window.innerWidth <= MIN_POINT;
  settingStore.updateConfig({
    isSidebarCompact: isCompact,
  });
};

onMounted(() => {
  autoCollapsed();
  window.onresize = () => {
    autoCollapsed();
  };
});

const goHome = () => {
  router.push({
    name: 'home',
  });
};

const changeHandler = (active) => {
  //菜单更新后改变页数   区分是某一个页面编辑回来还是点击菜单的操作
  commonCurrentPage.value = 1;
};
// const getLogo = () => {
//   if (collapsed.value) return AssetLogo;
//   return AssetLogoFull;
// };
</script>

<style lang="less" scoped></style>
