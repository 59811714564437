export const messagesStore = defineStore('messagesM', {
  state: () => ({
    selectedId: '', //选择内容后的id
    content: '', //选择的内容
    messageInfoType: 0, //0创建 1编辑 2详情
    messageId: '', //,查看或者编辑的id
  }),
  getters: {},
  actions: {},
  //   persist: true,
});
