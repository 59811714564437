<template>
  <div>
    <t-card :loading="loading">
<!--      <div v-if="showTool">-->
<!--        <Toolbar-->
<!--          v-if="!isLook"-->
<!--          :style="isLook ? toolBarStyleHidden : toolBarStyleShow"-->
<!--          :editor="editorRef"-->
<!--          :defaultConfig="toolbarConfig"-->
<!--          :mode="mode"-->
<!--        />-->
<!--      </div>-->
      <div style="z-index: 1000000">
        <Toolbar
          v-if="showTool"
          :style="showTool ? toolBarStyleShow : toolBarStyleHidden"
          :editor="editorRef"
          :defaultConfig="toolbarConfig"
          :mode="mode"
        />

        <Editor
          style="overflow-y: hidden"
          :style="{ height: height + 'px' }"
          v-model="defaultHtml"
          :defaultConfig="editorConfig"
          :mode="mode"
          @onCreated="handleCreated"
          @customPaste="myCustomPaste"
        />
      </div>
    </t-card>
  </div>
</template>

<script setup lang="ts">
import '@wangeditor/editor/dist/css/style.css'; // 引入 css
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import { uploadServer } from '@/api/upload';
import { MessagePlugin } from 'tdesign-vue-next';

import { commonStore } from '@/store';
import { createVNode } from 'vue';
const commonS = commonStore();
const { clientUrl, containerSas } = storeToRefs(commonS);

const props = defineProps({
  valueHtml: {
    default: '',
    type: String,
  },
  height: {
    default: 500,
    type: Number,
  },
  mode: {
    default: 'default',
    type: String,
  },
  isLook: {
    default: false,
    type: Boolean,
  },
  showTool: {
    default: true,
    type: Boolean,
  },
});

const defaultHtml = ref('');
watchEffect(() => {
  defaultHtml.value = props.valueHtml;
});

// 编辑器实例，必须用 shallowRef
const editorRef = shallowRef();

const handleCreated = (editor) => {
  editorRef.value = editor; // 记录 editor 实例，重要！
  //配置编辑器是否只读
  if (props.isLook == true) {
    editor.disable();
  } else {
    editor.enable();
  }
};

const loading = ref(false); //上传图片指示器

//父组件获取值
const getHtmlValue = () => {
  let value = editorRef.value.getHtml().replace(new RegExp('&nbsp;', 'g'), ' ');
  if (value == '<p><br></p>') {
    value = '';
  }
  return value;
};

const setHtmlText = (value: string) => {
  editorRef.value.setHtml(value);
};

const insertText = (text: string) => {
  editorRef.value.dangerouslyInsertHtml(text);
};

// 聚焦到编辑器
const editorFocus = () => {
  editorRef.value.focus();
};

defineExpose({
  getHtmlValue,
  setHtmlText,
  insertText,
  editorFocus,
});

const toolbarConfig = {
  excludeKeys: ['group-video'],
  // excludeKeys: ['fullScreen', 'group-video'],
};
const editorConfig = {
  placeholder: '请输入内容...',
  MENU_CONF: {
    uploadImage: {
      // 自定义上传
      async customUpload(file: File, insertFn: any) {
        // file 即选中的文件
        // 自己实现上传，并得到图片 url alt href

        const uuid = GetUUID();
        const hz = file.name.substring(file.name.lastIndexOf('.') + 1);
        let fileName = `${uuid}.${hz}`;

        // loading.value = true;
        MessagePlugin.success('正在上传图片');
        let uploadResult = await uploadServer.upload(clientUrl.value, fileName, containerSas.value, { raw: file });
        // loading.value = false; 导致插入图片失败

        if (uploadResult == true) {
          let uploadSuccessUrl = `${clientUrl.value}/${fileName}`;
          insertFn(uploadSuccessUrl);
        } else {
          MessagePlugin.warning('上传失败');
        }
      },
    },
  },
};

const myCustomPaste = (editor, event): boolean => {
  //   const html = event.clipboardData.getData('text/html'); // 获取粘贴的 html
  const text = event.clipboardData.getData('text/plain'); // 获取粘贴的纯文本
  //   const rtf = event.clipboardData.getData('text/rtf'); // 获取 rtf 数据（如从 word wsp 复制粘贴）

  if (text) {
    editor.insertText(text);
    // 阻止默认的粘贴行为
    event.preventDefault();
    return false;
  } else {
    // 继续执行默认的粘贴行为
    return true;
  }
};

// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
  const editor = editorRef.value;
  if (editor == null) return;
  editor.destroy();
});

const toolBarStyleShow = {
  'border-bottom': '1px solid #ccc',
};
const toolBarStyleHidden = {
  'border-bottom': '0px solid #ccc',
};

// 可通过 toolbarConfig 和 editorConfig 来修改菜单栏和编辑器的配置
</script>

<style lang="less" scoped></style>
