export const routerDataStore = defineStore('routerData', {
  // 用于路由跳转传输数据
  state: () => ({
    purcharseData: null, //编辑采购单数据

    questionId: '', //题库管理 编辑题目
    questionType: '', // 题库管理 新建编辑题目 add edit
  }),
  getters: {},
  actions: {},
  persist: true,
});
