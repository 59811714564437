import { createApp } from 'vue';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import TDesign from 'tdesign-vue-next';
import 'tdesign-vue-next/es/style/index.css';

import { store } from './store';
import router from './router';
import '@/style/index.less';
import './permission';
import App from './App.vue';

// Chrome51 版本以后，Chrome 增加了新的事件捕获机制－Passive Event Listeners；
import 'default-passive-events';

// 全局组件
import globalComponents from '@/pages/components/globalComponents';

const app = createApp(App);

app.component('QuillEditor', QuillEditor);
app.use(TDesign);
app.use(store);
app.use(router);
app.use(globalComponents);

app.mount('#app');
