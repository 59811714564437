<template>
  <t-card :loading="loading" :bordered="false">
    <t-form ref="passwordForm" :data="formData" :rules="rules" @submit="onSubmit">
      <t-form-item label="当前密码" name="currentPassword">
        <t-input v-model="formData.currentPassword" type="password" autocomplete="off"></t-input>
      </t-form-item>

      <t-form-item label="新密码" name="newPassword" help="密码长度为6-16个字符，区分大小写">
        <t-input v-model="formData.newPassword" type="password" autocomplete="off"></t-input>
      </t-form-item>

      <t-form-item label="确认密码" name="rePassword" help="">
        <t-input v-model="formData.rePassword" type="password" autocomplete="off"></t-input>
      </t-form-item>

      <t-form-item>
        <t-space size="small">
          <t-button theme="primary" type="submit">提交</t-button>
          <t-button theme="default" variant="base" type="reset">重置</t-button>
          <t-button theme="default" variant="base" @click="onBack">取消</t-button>
        </t-space>
      </t-form-item>
    </t-form>
  </t-card>
</template>
<script setup>
import { account } from '@/api/user/account';
import { MessagePlugin } from 'tdesign-vue-next';

const emits = defineEmits(['onFinish']);
const onBack = () => {
  emits('onFinish', false);
};

const startChange = () => {
  passwordForm.value.reset();
};
defineExpose({ startChange });

const passwordForm = ref(null);
const formData = reactive({
  currentPassword: '',
  newPassword: '',
  rePassword: '',
});

const loading = ref(false);
const onSubmit = ({ validateResult, firstError, e }) => {
  e.preventDefault();
  if (validateResult === true) {
    loading.value = true;
    let userId = window.localStorage.getItem('userId');
    account
      .updatePwd({
        userId: userId,
        newPassword: formData.newPassword,
        oldPassWord: formData.currentPassword,
      })
      .then((res) => {
        if (res.code == 200) {
          MessagePlugin.success('修改密码成功');
          emits('onFinish', true);
        } else MessagePlugin.warning(res?.msg);

        loading.value = false;
      });
  } else {
    MessagePlugin.warning(firstError);
  }
};

const rePassword = (val) => {
  return formData.newPassword == val;
};

const passwordValidator = (val) => {
  if (val.length > 0 && val.length < 6) {
    return { result: false, message: '密码太短', type: 'error' };
  }
  if (val.length > 16) {
    return { result: false, message: '密码太长', type: 'warning' };
  }
  return { result: true, message: '', type: 'success' };
};

const rules = {
  currentPassword: [{ required: true, message: '当前密码必填', type: 'error' }],
  newPassword: [{ required: true, message: '密码必填', type: 'error' }, { validator: passwordValidator }],
  rePassword: [
    // 自定义校验规则
    { required: true, message: '确认密码必填', type: 'error' },
    { validator: rePassword, message: '两次密码不一致' },
  ],
};
</script>
