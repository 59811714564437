import myAxios from '@/utils';

/**
 * 用户账号的查询
 */
type userAccountForm = {
  pageNo: Number; //
  pageSize: Number; //
  userName: string; //用户名
  phonenumber: string; //手机号
  userId?: string; //用户ID
  status?: Number; //状态0启用1停用
  roles: string;
};
/**
 * 删除
 */
type userIdForm = {
  userId: string; //用户ID
  password: string;
};
/**
 * 新增/编辑账号
 */
type AccountForm = {
  userName: string; //
  email: string; //
  phonenumber: string;
  password: string;
  roleIds: Array<number>;
};

const account = {
  /**
   * 获取列表的方法
   */
  getList: (params: userAccountForm) => {
    return (myAxios as any)(
      Object.assign(
        {
          url: 'operate-service/operate/user/list',
          method: 'get',
        },
        { data: params },
      ),
    );
  },
  /**
   * 新增账号
   */
  addAccount: (params: AccountForm) => {
    return (myAxios as any)(
      Object.assign(
        {
          url: 'operate-service/operate/user',
          method: 'post',
        },
        { data: params },
      ),
    );
  },
  /**
   * 角色列表
   */
  roleList: () => {
    return (myAxios as any)(
      Object.assign(
        {
          url: 'operate-service/operate/role/optionselect',
          method: 'get',
        },
        {},
      ),
    );
  },
  /**
   * translation禁用接口
   */
  translation: (params: object) => {
    return (myAxios as any)(
      Object.assign(
        {
          url: 'operate-service/operate/user/changeStatus',
          method: 'put',
        },
        { data: params },
      ),
    );
  },
  /**
   * translation删除接口
   */
  delete: (params: userIdForm) => {
    return (myAxios as any)(
      Object.assign(
        {
          url: '/operate-service/operate/user/' + params.userId,
          method: 'DELETE',
        },
        { data: params },
      ),
    );
  },
  /**
   * translation重置密码
   */
  restPassword: (params: userIdForm) => {
    return (myAxios as any)(
      Object.assign(
        {
          url: 'operate-service/operate/user/resetPwd',
          method: 'put',
        },
        { data: params },
      ),
    );
  },
  /**
   * translation详情
   */
  edit: (params: userIdForm) => {
    return (myAxios as any)(
      Object.assign(
        {
          url: '/operate-service/operate/user/getInfo/' + params.userId,
          method: 'get',
        },
        { data: params },
      ),
    );
  },
  /**
   * translation编辑
   */
  editAccount: (params: AccountForm) => {
    return (myAxios as any)(
      Object.assign(
        {
          url: '/operate-service/operate/user',
          method: 'put',
        },
        { data: params },
      ),
    );
  },

  /**
   * 修改密码
   */
  updatePwd: (params: object) => {
    return (myAxios as any)(
      Object.assign(
        {
          url: 'operate-service/operate/user/updatePwd',
          method: 'put',
        },
        { data: params },
      ),
    );
  },
};

export { account };
