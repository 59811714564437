export const courseStore = defineStore('courses', {
  state: () => ({
    isEditCourse: false, //是编辑课程还是新建课程
    phase: 0, //新建课程或者编辑课程的步骤
    courseId: '', //新建或者编辑课程需要的id
  }),
  getters: {},
  actions: {},
  persist: true,
});
