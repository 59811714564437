//详情页顶部面包屑model
interface DetailBreadObject {
  topName: string;
  middleName: string;
  detailName: string;
}

//详情页每一项的类型
export enum DetailItemType {
  Text,
  StatusText, //用于展示有状态的文字
  Images,
  MImages, //全部展开所有图片
  TextArr, //多个数据可以用标签输入框来展示
  TextArea,
  Table,
  Rich, //富文本
  Link, //需要点击查看内容，如PDF
}

export interface DetialTableModle {
  data: Array<Object>;
  colums: Array<Object>;
  typeName?: String; //预留字段 判断从哪个地方过来的 以便于以后对table处理
}

//详情页每一项Model
export interface DetailModel {
  type: DetailItemType;
  name: String; //名称
  id?: String; //采购课程包id
  textValue?: String; //对应的值 type = Text
  textArray?: Array<String>; //如果是多选的 从这里面取值 type = TextArr
  imageUrls?: Array<String>; //图片的urls type = TextArr
  textStatus?: number; // 0 表示否 1表示是
  LinkUrl?: String;
  tableData?: Array<Object>; //图片的urls type = TextArr
  tableColumes?: Array<String>; //图片的urls type = TextArr
  tableType?: String; //预留字段 判断从哪个地方过来的 以便于以后对table处理
}

export const commonStore = defineStore('commonStore', {
  state: () => ({
    tabValue: 1, //用于广告管理， tab值

    clientUrl: '', //上传文件相关
    containerSas: '', //上传文件相关

    //详情页数据相关
    detailVisible: false, //公共drawervisible
    drawerWidth: '1000px',
    headerTitle: '', //drawer弹窗头部
    detailLoading: false, //有详情页是需要调用接口的  需要loading
    bread: {} as DetailBreadObject,
    infoData: [] as Array<DetailModel>,

    //用于保存pageNumber  @page-change时赋值   onmounted时paginnation.current设置
    commonCurrentPage: 1, //进入新增或者编辑的时候 回退还应该是原来的页码
  }),
  getters: {},
  actions: {},
  //   persist: true,
});
