//整个营销管理模块使用整个store

export const marketingStore = defineStore('marketingM', {
  state: () => ({
    couponId: '', //优惠券id
    couponType: 0, //0 创建 1编辑
  }),
  getters: {},
  actions: {},
});
