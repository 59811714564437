import Layout from '@/layouts/index.vue';
import ListIcon from '@/assets/assets-slide-list.svg';
import FormIcon from '@/assets/assets-slide-form.svg';
import DetailIcon from '@/assets/assets-slide-detail.svg';
import DashboardIcon from '@/assets/assets-slide-dashboard.svg';

export default [
  {
    path: '/home',
    name: 'home',
    component: Layout,
    redirect: '/home/',
    meta: { title: '首页', icon: 'home', single: true, orderNo: 1, roleCode: 1 },
    children: [
      {
        path: '/home/',
        name: 'home_home',
        // component: () => import('@/pages/list/home/home.vue'),
        component: () => import('@/pages/home/home.vue'),
        meta: { title: '首页', roleCode: 101 },
      },
      {
        path: '/details',
        name: 'detailsM',
        meta: { hidden: true, roleCode: 101 },
        component: () => import('@/pages/components/details.vue'),
      },
    ],
  },
  {
    path: '/list',
    name: 'list',
    component: Layout,
    redirect: '/list/base',
    meta: { title: '内容管理', icon: 'layers', orderNo: 2, roleCode: 2 },
    children: [
      {
        path: 'courseList',
        name: 'courseList',
        component: () => import('@/pages/list/course/courseList/index.vue'),
        meta: { title: '课程管理', roleCode: 201 },
      },
      {
        path: 'courseListAddOrUpdate',
        name: 'courseListAddOrUpdate',
        meta: { hidden: true, roleCode: 201 },
        component: () => import('@/pages/list/addOrUpdate/courseAddOrUpdate/index.vue'),
      },
      {
        path: 'courseListBoughtList',
        name: 'courseListBoughtList',
        meta: { hidden: true, roleCode: 201 },
        component: () => import('@/pages/list/boughtList/boughtList.vue'),
      },
      {
        path: 'courseListDetails',
        name: 'courseListDetails',
        meta: { hidden: true, roleCode: 201 },
        component: () => import('@/pages/list/course/courseList/courseDetails.vue'),
      },
      {
        path: 'questionList',
        name: 'questionList',
        component: () => import('@/pages/list/question/questionList/index.vue'),
        meta: { title: '题库管理', roleCode: 202 },
      },
      {
        path: 'questionListAddOrUpdate',
        name: 'questionListAddOrUpdate',
        meta: { hidden: true, roleCode: 202 },
        component: () => import('@/pages/list/addOrUpdate/questionAddOrUpdate/index.vue'),
      },
      {
        path: 'questionListImportQuestion',
        name: 'questionListImportQuestion',
        meta: { hidden: true, roleCode: 202 },
        component: () => import('@/pages/list/import/importQuestion/importQuestion.vue'),
      },
      {
        path: 'measurementList',
        name: 'measurementList',
        component: () => import('@/pages/list/measurement/measurementList/index.vue'),
        meta: { title: '测评管理', roleCode: 203 },
      },
      {
        path: 'measurementListAddOrUpdate',
        name: 'measurementListAddOrUpdate',
        meta: { hidden: true, roleCode: 203 },
        component: () => import('@/pages/list/addOrUpdate/measurementAddOrUpdate/index.vue'),
      },
      {
        path: 'coursePackageList',
        name: 'coursePackageList',
        component: () => import('@/pages/list/coursePackage/coursePackageList/index.vue'),
        meta: { title: '课程包', roleCode: 204 },
      },
      {
        path: 'coursePackageListAddOrUpdate',
        name: 'coursePackageListAddOrUpdate',
        meta: { hidden: true, roleCode: 204 },
        component: () => import('@/pages/list/addOrUpdate/coursePackageAddOrUpdate/index.vue'),
      },
      {
        path: 'appraise',
        name: 'appraise',
        component: () => import('@/pages/list/appraise/index.vue'),
        meta: { title: '评价分类', roleCode: 205 },
      },
      {
        path: 'certificateList',
        name: 'certificateList',
        component: () => import('@/pages/list/certificate/certificateList/index.vue'),
        meta: { title: '证书管理', roleCode: 206 },
      },
      {
        path: 'certificateListAddOrUpdate',
        name: 'certificateListAddOrUpdate',
        meta: { hidden: true, roleCode: 206 },
        component: () => import('@/pages/list/addOrUpdate/certificateAddOrUpdate/index.vue'),
      },
      {
        path: 'subject',
        name: 'subject',
        component: () => import('@/pages/list/subject/index.vue'),
        meta: { title: '学科分类', roleCode: 207 },
      },
      {
        path: 'coursewareLibrary',
        name: 'coursewareLibrary',
        component: () => import('@/pages/list/coursewareLibrary/index.vue'),
        meta: { title: '课件中心', roleCode: 208 },
      },
    ],
  },

  {
    path: '/operateManagement',
    name: 'operateManagement',
    component: Layout,
    redirect: '/operateManagement/base',
    meta: { title: '运营管理', icon: 'laptop', orderNo: 2, roleCode: 3 },
    children: [
      {
        path: 'news',
        name: 'news',
        meta: { title: '新闻管理', roleCode: 301 },
        component: () => import('@/pages/operateManagement/news/index.vue'),
      },
      {
        path: 'createNews',
        name: 'createNews',
        meta: { roleCode: 301, hidden: true },
        component: () => import('@/pages/operateManagement/addOrUpdate/createNews/index.vue'),
      },
      {
        path: 'purchase',
        name: 'purchase',
        component: () => import('@/pages/operateManagement/purchase/index.vue'),
        meta: { title: '采购管理', roleCode: 301 },
      },
      {
        path: 'purchaseUpdateOrDetails',
        name: 'purchaseUpdateOrDetails',
        meta: { hidden: true, roleCode: 301 },
        component: () => import('@/pages/operateManagement/addOrUpdate/purchaseUpdateOrDetails/index.vue'),
      },
      {
        path: 'purchaseAdd',
        name: 'purchaseAdd',
        meta: { hidden: true, roleCode: 301 },
        component: () => import('@/pages/operateManagement/addOrUpdate/purchaseAdd/index.vue'),
      },
      {
        path: 'purchaseNext',
        name: 'purchaseNext',
        meta: { hidden: true, roleCode: 301 },
        component: () => import('@/pages/operateManagement/addOrUpdate/purchaseAdd/purchaseNext/index.vue'),
      },
      {
        path: 'activity',
        name: 'activity',
        component: () => import('@/pages/operateManagement/activity/index.vue'),
        meta: { title: '活动管理', roleCode: 302 },
      },
      {
        path: 'activityAddOrUpdate',
        name: 'activityAddOrUpdate',
        meta: { hidden: true, roleCode: 302 },
        component: () => import('@/pages/operateManagement/addOrUpdate/activityAddOrUpdate/activity/index.vue'),
      },
      {
        path: 'activityWorkshopAddOrUpdate',
        name: 'activityWorkshopAddOrUpdate',
        meta: { hidden: true, roleCode: 302 },
        component: () => import('@/pages/operateManagement/addOrUpdate/activityAddOrUpdate/workshop/index.vue'),
      },
      {
        path: 'activityLiveAddOrUpdate',
        name: 'activityLiveAddOrUpdate',
        meta: { hidden: true, roleCode: 302 },
        component: () => import('@/pages/operateManagement/addOrUpdate/activityAddOrUpdate/live/index.vue'),
      },
      {
        path: 'advertisement',
        name: 'advertisement',
        component: () => import('@/pages/operateManagement/advertisement/index.vue'),
        meta: { title: '广告管理', roleCode: 303 },
      },
      {
        path: 'advertisementIndexAddOrUpdate',
        name: 'advertisementIndexAddOrUpdate',
        meta: { hidden: true, roleCode: 303 },
        component: () => import('@/pages/operateManagement/addOrUpdate/indexAddOrUpdate/index.vue'),
      },
      {
        path: 'advertisementBannerAddOrUpdate',
        name: 'advertisementBannerAddOrUpdate',
        meta: { hidden: true, roleCode: 303 },
        component: () => import('@/pages/operateManagement/addOrUpdate/bannerAddOrUpdate/index.vue'),
      },
      {
        path: 'addNewWebGate',
        name: 'addNewWebGate',
        meta: { hidden: true, roleCode: 303 },
        component: () => import('@/pages/operateManagement/addOrUpdate/webGateAddOrUpdate/index.vue'),
      },
      {
        path: 'recommend',
        name: 'recommend',
        component: () => import('@/pages/operateManagement/recommend/index.vue'),
        meta: { title: '推荐管理', roleCode: 304 },
      },
      {
        path: 'feedback',
        name: 'feedback',
        component: () => import('@/pages/operateManagement/feedback/feedback.vue'),
        meta: { title: '意见反馈', roleCode: 305 },
      },
      {
        path: 'faq',
        name: 'faq',
        component: () => import('@/pages/operateManagement/faq/faq.vue'),
        meta: { title: '常见问题', roleCode: 306 },
      },
      {
        path: 'messages',
        name: 'messagesM',
        component: () => import('@/pages/operateManagement/messages/messagesList.vue'),
        meta: { title: '通知管理', roleCode: 307 },
      },
      {
        path: 'messagesInfo',
        name: 'messagesInfo',
        component: () => import('@/pages/operateManagement/messages/messageInfo.vue'),
        meta: { hidden: true, roleCode: 307 },
      },
      {
        path: 'credits',
        name: 'creditsM',
        component: () => import('@/pages/operateManagement/credits/creditsList.vue'),
        meta: { title: '积分统计', roleCode: 308 },
      },
      {
        path: 'intresting',
        name: 'intrestingM',
        component: () => import('@/pages/operateManagement/intresting/intrestingList.vue'),
        meta: { title: '兴趣管理', roleCode: 309 },
      },
    ],
  },

  {
    path: '/user',
    name: 'user',
    component: Layout,
    redirect: '/user/index',
    meta: { title: '基础管理', icon: 'setting', orderNo: 6, roleCode: 4 },
    children: [
      {
        path: 'account',
        name: 'AccountIndex',
        component: () => import('@/pages/user/account/index.vue'),
        meta: { title: '账号管理', roleCode: 401 },
      },
      //   用于面包屑 path要求包含上一层的path  meta写hidden
      {
        path: 'accountAddOrUpdate',
        name: 'accountAddOrUpdate',
        meta: { hidden: true, roleCode: 401 },
        component: () => import('@/pages/list/addOrUpdate/accountAddOrUpdate/index.vue'),
      },
      {
        path: 'role',
        name: 'role',
        component: () => import('@/pages/user/role/index.vue'),
        meta: { title: '角色管理', roleCode: 402 },
      },
      {
        path: 'roleAddOrUpdate',
        name: 'roleAddOrUpdate',
        meta: { hidden: true, roleCode: 402 },
        component: () => import('@/pages/list/addOrUpdate/roleAddOrUpdate/index.vue'),
      },
      {
        path: 'operationalLogs',
        name: 'OperationalLogsIndex',
        component: () => import('@/pages/user/operationalLogs/index.vue'),
        meta: { title: '操作日志', roleCode: 403 },
      },
      {
        path: 'versionList',
        name: 'versionList',
        component: () => import('@/pages/user/versions/versions.vue'),
        meta: { title: '版本管理', roleCode: 404 },
      },
    ],
  },
  {
    path: '/userManagement',
    name: 'userManagement',
    component: Layout,
    redirect: '/userManagement/user',
    meta: { title: '用户管理', icon: 'user', orderNo: 5, roleCode: 5 },
    children: [
      {
        path: 'user',
        name: 'userManagement_user',
        component: () => import('@/pages/userManagement/usersM/index.vue'),
        meta: { title: '用户管理', roleCode: 501 },
      },
      {
        path: 'teacher',
        name: 'userManagement_teacher',
        component: () => import('@/pages/userManagement/teachersM/index.vue'),
        meta: { title: '认证管理', roleCode: 502 },
      },
      {
        path: 'safe',
        name: 'userManagement_safe',
        component: () => import('@/pages/userManagement/safetyM/index.vue'),
        meta: { title: '安全日志', roleCode: 503 },
      },
    ],
  },
  {
    path: '/deal',
    name: 'deal',
    component: Layout,
    redirect: '/deal/orders',
    meta: { title: '交易管理', icon: 'money-circle', orderNo: 4, roleCode: 6 },
    children: [
      {
        path: 'orders',
        name: 'dealManagement_orders',
        component: () => import('@/pages/dealManagement/ordersM/ordersM.vue'),
        meta: { title: '订单管理', roleCode: 601 },
      },
      {
        path: 'close',
        name: 'dealManagement_close',
        component: () => import('@/pages/dealManagement/closeM/close.vue'),
        meta: { title: '结算管理', roleCode: 602 },
      },
    ],
  },
  {
    path: '/checks',
    name: 'checks',
    component: Layout,
    redirect: '/checks/dynamics',
    meta: { title: '社区治理', icon: 'usergroup', orderNo: 3, roleCode: 7 },
    children: [
      {
        path: 'dynamics',
        name: 'dynamicsCheck',
        component: () => import('@/pages/checks/dynamics/dynamics.vue'),
        meta: { title: '社区动态', roleCode: 701 },
      },
      {
        path: 'articles',
        name: 'articlesCheck',
        component: () => import('@/pages/checks/articles/articles.vue'),
        meta: { title: '文章审核', roleCode: 702 },
      },
      {
        path: 'comments',
        name: 'icommentsCheck',
        component: () => import('@/pages/checks/comments/comments.vue'),
        meta: { title: '评论管理', roleCode: 703 },
      },
      {
        path: 'informs',
        name: 'informsCheck',
        component: () => import('@/pages/checks/informs/inform.vue'),
        meta: { title: '举报审核', roleCode: 704 },
      },
      {
        path: 'discuss',
        name: 'discussCheck',
        component: () => import('@/pages/checks/coursesDiscuss/coursesDiscuss.vue'),
        meta: { title: '课程讨论', roleCode: 705 },
      },
      {
        path: 'evaluates',
        name: 'evaluatesCheck',
        component: () => import('@/pages/checks/courseEvaluates/courseEvaluate.vue'),
        meta: { title: '课程评价', roleCode: 706 },
      },
      {
        path: 'topics',
        name: 'topicsCheck',
        component: () => import('@/pages/checks/topics/topic.vue'),
        meta: { title: '话题管理', roleCode: 707 },
      },
      {
        path: 'virtualAdds',
        name: 'virtualAddCheck',
        component: () => import('@/pages/checks/virtualAdds/virtualAdd.vue'),
        meta: { title: '虚增管理', roleCode: 708 },
      },
      {
        path: 'words',
        name: 'wordsCheck',
        component: () => import('@/pages/checks/words/wordsLabarary.vue'),
        meta: { title: '搜索过滤', roleCode: 709 },
      },
      // {
      //   path: 'level',
      //   name: 'checkLevel',
      //   component: () => import('@/pages/checks/checkLevel/checkLevel.vue'),
      //   meta: { title: '敏感度' },
      // },
    ],
  },

  {
    path: '/marketing',
    name: 'marketingM',
    component: Layout,
    redirect: '/home/coupon',
    meta: { title: '营销管理', icon: 'gift', orderNo: 3, roleCode: 8 },
    children: [
      {
        path: 'coupon',
        name: 'couponM',
        component: () => import('@/pages/marketing/coupons/coupon.vue'),
        meta: { title: '优惠券', roleCode: 801 },
      },
      {
        path: 'couponCreat',
        name: 'creatCouponM',
        meta: { hidden: true, roleCode: 801 },
        component: () => import('@/pages/marketing/coupons/creatCoupon.vue'),
      },
      {
        path: 'qRCodeManage',
        name: 'qRCodeManage',
        component: () => import('@/pages/marketing/qRCode/index.vue'),
        meta: { title: '二维码管理', roleCode: 802 },
      },
    ],
  },
  {
    path: '/distributionManagement',
    name: 'distributionManagement',
    component: Layout,
    redirect: '/distributionManagement/statistics',
    meta: { title: '分销管理', icon: 'layers', orderNo: 3, roleCode: 10 },
    children: [
      {
        path: 'statistics',
        name: 'statistics',
        component: () => import('@/pages/distributionManagement/distributionStatistics/index.vue'),
        meta: { title: '分销统计', roleCode: 1001 },
      },
      {
        path: 'distributor',
        name: 'distributor',
        component: () => import('@/pages/distributionManagement/distributorManage/index.vue'),
        meta: { title: '分销员管理', roleCode: 1002 },
      },
      {
        path: 'cashDetail',
        name: 'cashDetail',
        component: () => import('@/pages/distributionManagement/cashDetail/index.vue'),
        meta: { title: '提现管理', roleCode: 1002 },
      },
      {
        path: 'commission',
        name: 'commission',
        component: () => import('@/pages/distributionManagement/commission/index.vue'),
        meta: { title: '佣金配置', roleCode: 1002 },
      },
      {
        path: 'shareList',
        name: 'shareList',
        component: () => import('@/pages/distributionManagement/shareList/index.vue'),
        meta: { title: '分享记录', roleCode: 1002 },
      },
    ],
  },
  {
    path: '/AIlManagement',
    name: 'AIlManagement',
    component: Layout,
    redirect: '/AIlManagement/sort',
    meta: { title: '大模型管理', icon: 'layers', orderNo: 7, roleCode: 9 },
    children: [
      {
        path: 'sort',
        name: 'sort',
        component: () => import('@/pages/AIlManagement/sortManagement/index.vue'),
        meta: { title: '分类管理', roleCode: 901 },
      },
      {
        path: 'prompt',
        name: 'prompt',
        component: () => import('@/pages/AIlManagement/promptManagement/index.vue'),
        meta: { title: '提示词管理', roleCode: 902 },
      },
      {
        path: 'tokens',
        name: 'tokens',
        component: () => import('@/pages/AIlManagement/tokensManagement/index.vue'),
        meta: { title: 'tokens消费明细', roleCode: 903 },
      },
    ],
  },

  //   {
  //     path: '/form',
  //     name: 'form',
  //     component: Layout,
  //     redirect: '/form/base',
  //     meta: { title: '表单页', icon: FormIcon, hidden: true },
  //     children: [
  //       {
  //         path: 'base',
  //         name: 'FormBase',
  //         component: () => import('@/pages/form/base/index.vue'),
  //         meta: { title: '基础表单页' },
  //       },
  //       {
  //         path: 'step',
  //         name: 'FormStep',
  //         component: () => import('@/pages/form/step/index.vue'),
  //         meta: { title: '分步表单页' },
  //       },
  //     ],
  //   },
  //   {
  //     path: '/detail',
  //     name: 'detail',
  //     component: Layout,
  //     redirect: '/detail/base',
  //     meta: { title: '详情页', icon: DetailIcon, hidden: true },
  //     children: [
  //       {
  //         path: 'base',
  //         name: 'DetailBase',
  //         component: () => import('@/pages/detail/base/index.vue'),
  //         meta: { title: '基础详情页' },
  //       },
  //       {
  //         path: 'advanced',
  //         name: 'DetailAdvanced',
  //         component: () => import('@/pages/detail/advanced/index.vue'),
  //         meta: { title: '多卡片详情页' },
  //       },
  //       {
  //         path: 'deploy',
  //         name: 'DetailDeploy',
  //         component: () => import('@/pages/detail/deploy/index.vue'),
  //         meta: { title: '数据详情页' },
  //       },
  //       {
  //         path: 'secondary',
  //         name: 'DetailSecondary',
  //         component: () => import('@/pages/detail/secondary/index.vue'),
  //         meta: { title: '二级详情页' },
  //       },
  //     ],
  //   },
  //   {
  //     path: '/result',
  //     name: 'result',
  //     component: Layout,
  //     redirect: '/result/success',
  //     meta: { title: '结果页', icon: 'check-circle', hidden: true },
  //     children: [
  //       {
  //         path: 'success',
  //         name: 'ResultSuccess',
  //         component: () => import('@/pages/result/success/index.vue'),
  //         meta: { title: '成功页' },
  //       },
  //       {
  //         path: 'fail',
  //         name: 'ResultFail',
  //         component: () => import('@/pages/result/fail/index.vue'),
  //         meta: { title: '失败页' },
  //       },
  //       {
  //         path: 'network-error',
  //         name: 'ResultNetworkError',
  //         component: () => import('@/pages/result/network-error/index.vue'),
  //         meta: { title: '网络异常' },
  //       },
  //       {
  //         path: '403',
  //         name: 'Result403',
  //         component: () => import('@/pages/result/403/index.vue'),
  //         meta: { title: '无权限' },
  //       },
  //       {
  //         path: '404',
  //         name: 'Result404',
  //         component: () => import('@/pages/result/404/index.vue'),
  //         meta: { title: '访问页面不存在页' },
  //       },
  //       {
  //         path: '500',
  //         name: 'Result500',
  //         component: () => import('@/pages/result/500/index.vue'),
  //         meta: { title: '服务器出错页' },
  //       },
  //       {
  //         path: 'browser-incompatible',
  //         name: 'ResultBrowserIncompatible',
  //         component: () => import('@/pages/result/browser-incompatible/index.vue'),
  //         meta: { title: '浏览器不兼容页' },
  //       },
  //       {
  //         path: 'maintenance',
  //         name: 'ResultMaintenance',
  //         component: () => import('@/pages/result/maintenance/index.vue'),
  //         meta: { title: '系统维护页' },
  //       },
  //     ],
  //   },
];
