import myAxios from '@/utils';

/**
 * 获取验证码
 */
type emailResult = {
  email: String; //邮箱
};
/**
 * 登录
 */
type loginResult = {
  userName: String; //邮箱
  password: String; //密码
  code: String; //验证码
};

const ArticleServer = {
  // 获取验证码
  getSendCode: (params: emailResult) => {
    return (myAxios as any)(
      Object.assign(
        {
          url: '/auth-service/auth/sys/sendCode',
          method: 'post',
        },
        { data: params },
      ),
    );
  },
  // 登录
  login: (params: loginResult) => {
    return (myAxios as any)(
      Object.assign(
        {
          url: '/auth-service/auth/sys/login',
          method: 'post',
        },
        { data: params },
      ),
    );
  },
  // 查看详情
  getInfo: () => {
    return (myAxios as any)(
      Object.assign(
        {
          url: '/operate-service/operate/user/getInfo',
          method: 'get',
        },
        {},
      ),
    );
  },

  logout: () => {
    return (myAxios as any)(
      Object.assign(
        {
          url: '/auth-service/auth/sys/logout',
          method: 'delete',
        },
        {},
      ),
    );
  },
};

export { ArticleServer };
