import { defineStore } from 'pinia';
import { TOKEN_NAME } from '@/config/global';
import { store, usePermissionStore } from '@/store';

const InitUserInfo = {
  roles: [],
};

export const useUserStore = defineStore('user', {
  state: () => ({
    token: localStorage.getItem(TOKEN_NAME) || 'main_token', // 默认token不走权限
    userInfo: InitUserInfo,
  }),
  getters: {
    roles: (state) => {
      return state.userInfo?.roles;
    },
  },
  actions: {
    setUserRoles(array: number[]) {
      this.userInfo = {
        name: 'td_main',
        roles: array, //如果是all 会加载所有路由
      };
      //   Log('actions', this.userInfo);
    },
    // async login(userInfo: Record<string, unknown>) { 之前这个方法是为了设置token=main_token
    //   const mockLogin = async (userInfo: Record<string, unknown>) => {
    // 登录请求流程
    // const { account, password } = userInfo;
    // if (account !== 'td') {
    //   return {
    //     code: 401,
    //     message: '账号不存在',
    //   };
    // }
    // if (['main_', 'dev_'].indexOf(password) === -1) {
    //   return {
    //     code: 401,
    //     message: '密码错误',
    //   };
    // }
    // const token = {
    //   main_: 'main_token',
    //   dev_: 'dev_token',
    // }[password];
    //     return {
    //       code: 200,
    //       message: '登陆成功',
    //       data: 'main_token',
    //     };
    //   };

    //   const res = await mockLogin(userInfo);
    //   if (res.code === 200) {
    //     this.token = res.data;
    //   } else {
    //     throw res;
    //   }
    // },
    // async getUserInfo() {
    //   const mockRemoteUserInfo = async (token: string) => {
    //     // if (token === 'main_token') {
    //     return {
    //       name: 'td_main',
    //       roles: ['all'], //如果是all 会加载所有路由
    //     };
    //     // }
    //     // return { //搭建框架自带 理解为举例的开发环境
    //     //   name: 'td_dev',
    //     //   roles: ['UserIndex', 'DashboardBase', 'login'],
    //     // };
    //   };

    //   const res = await mockRemoteUserInfo(this.token);

    //   this.userInfo = res; // roles: ['all']赋值给store
    // },
    async logout() {
      //退出登录清空数据
      localStorage.removeItem(TOKEN_NAME);
      this.token = '';
      this.userInfo = InitUserInfo;
    },
    async removeToken() {
      this.token = '';
    },
  },
  persist: {
    afterRestore: (ctx) => {
      if (ctx.store.roles && ctx.store.roles.length > 0) {
        const permissionStore = usePermissionStore();
        permissionStore.initRoutes(ctx.store.roles);
      }
    },
  },
});

export function getUserStore() {
  return useUserStore(store);
}
