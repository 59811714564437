<template>
  <t-image-viewer
    v-model:visible="imagesVisbile"
    :images="viewImages"
    closeOnOverlay
    :index="viewIndex"
    @index-change="onIndexChange"
  >
  </t-image-viewer>
  <div style="width: 100%; display: flex; justify-content: center">
    <div style="width: calc(100% - 60px); margin-left: 30px">
      <t-form label-align="right" :label-width="120">
        <div v-for="data in detailModels.infoData.value" style="margin-bottom: 15px">
          <t-form-item :label="`${data.name}`" :name="data.name">
            <div v-if="data.type == DetailItemType.Text">{{ data.textValue ?? '--' }}</div>
            <!-- <t-input
              v-if="data.type == DetailItemType.Text"
              v-model="data.textValue"
              readonly
              placeholder=""
              style="cursor: default"
            ></t-input> -->
            <div
              v-else-if="data.type == DetailItemType.StatusText"
              :class="{ warningClass: data.textStatus == 0, successClass: data.textStatus == 1 }"
            >
              {{ data.textValue }}
            </div>
            <span class="t-button-link" v-if="data.name == '采购课程包'" @click="showDetails(data)">详情</span>
            <!-- <t-input
              v-else-if="data.type == DetailItemType.StatusText"
              v-model="data.textValue"
              readonly
              placeholder=""
              :class="{ warningClass: data.textStatus == 0, successClass: data.textStatus == 1 }"
            ></t-input> -->
            <t-tagInput
              v-else-if="data.type == DetailItemType.TextArr && data.textArray"
              readonly
              v-model="data.textArray"
              placeholder=""
            />
            <t-textarea
              v-else-if="data.type == DetailItemType.TextArea"
              v-model="data.textValue"
              readonly
              placeholder=""
            />
            <t-button
              v-else-if="data.type == DetailItemType.Link"
              variant="text"
              theme="primary"
              @click="lookLinkContent(data.LinkUrl)"
              >查看</t-button
            >
            <t-image
              v-else-if="data.type == DetailItemType.Images && data.imageUrls"
              v-show="data.imageUrls"
              :src="getFirstImageUlr(data.imageUrls)"
              :style="{ width: '120px', height: '120px' }"
              fit="cover"
              @click="showImgs(data.imageUrls)"
            />
            <MyEditor
              v-else-if="data.type == DetailItemType.Rich"
              ref="MyEditorRef"
              :height="320"
              :value-html="getHtmlValue(data.textValue)"
              :is-look="true"
              style="width: 100%"
            />
            <div v-else-if="data.type == DetailItemType.MImages && data.imageUrls" class="MImages">
              <t-image
                v-for="(imageUrl, index) in data.imageUrls"
                :src="imageUrl"
                style="width: 120px; height: 120px; margin-right: 10px"
                fit="cover"
                @click="showImgs(data.imageUrls, index)"
              />
            </div>
          </t-form-item>
        </div>
      </t-form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAttrs } from 'vue';
//详情页数据
import { DetailItemType, DetailModel } from '@/store';
import { commonStore } from '@/store';
const commonS = commonStore();
const detailModels = storeToRefs(commonS);

const getFirstImageUlr = (array: any) => {
  return array[0];
};

const imagesVisbile = ref(false);
const showImgs = (array: any, index: number = 0) => {
  viewIndex.value = 0;
  viewImages.value = array;
  imagesVisbile.value = true;
  viewIndex.value = index;
};
const viewImages = ref([]);
const viewIndex = ref(0);
const onIndexChange = (i) => {
  viewIndex.value = i;
};

const getHtmlValue = (content) => {
  let str = '';
  str = content;
  if (str.length < 1) {
    str = '--';
  }
  return str;
};

const lookLinkContent = (url) => {
  if (url) {
    window.open(url);
  }
};
const attrs: any = useAttrs();
const showDetails = (data) => {
  attrs.onShowDetails(data);
};
</script>

<style lang="less" scoped>
:deep(.t-form__label) {
  color: var(--td-text-color-secondary) !important;
}
:deep(.t-input) {
  //   border-color: var(--td-gray-color-1) !important;
  border-color: transparent !important;
}
:deep(.t-textarea__inner) {
  border-color: var(--td-gray-color-1) !important;
  //   border-color: transparent !important;
}

.successClass {
  :deep(.t-input__inner) {
    color: #00a870;
  }
}

.warningClass {
  :deep(.t-input__inner) {
    color: #ed7b2f;
  }
  color: #ed7b2f;
}
</style>

<style>
.t-button-link {
  margin-left: 10px;
}
.tdesign-demo-image-viewer__ui-image--hover {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
  color: var(--td-text-color-anti);
  line-height: 22px;
  transition: 0.2s;
}
.MImages {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
</style>
