import { v4 as uuidv4 } from 'uuid';
import xss from 'xss';

export function GetUUID(): string {
  return uuidv4();
}

export function Log(message?: any, ...optionalParams: any[]) {
  console.log(message, ...optionalParams);
}

export function XSS(source: string): string {
  //   Log('xss:', source);
  var html = xss(source, {
    whiteList: {}, // 白名单为空，表示过滤所有标签
    stripIgnoreTag: true, // 过滤所有非白名单标签的HTML
    // stripIgnoreTagBody: true,
    stripIgnoreTagBody: ['script', 'img'], // script标签较特殊，需要过滤标签中间的内容
  });

  //   Log('xss后:', html);
  return html;
}

export function validateIDCard(idCard: string) {
  var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  return reg.test(idCard);
}

//用户隐私优化
export class ETool {
  name: string = 'lkll';
  static privacyPhoneNumber(phone: string) {
    let reg = /^(\d{3})\d{4}(\d{4})$/;
    return phone.replace(reg, '$1****$2');
  }

  static validatePhoneNumber(phone: string) {
    const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    return reg.test(phone);
  }

  static privacyRealname(name: string, frontLen: number = 1) {
    if (name.length < 1) {
      return name;
    }
    let back = '';
    for (var i = 1; i < name.length; i++) {
      back += '*';
    }
    // 最后的返回值由三部分组成
    return name.substring(0, frontLen) + back;
  }

  static privacyIDCard(idcard: string, frontLen: number = 3, endLen: number = 4) {
    if (idcard.length < frontLen + endLen) {
      return idcard;
    }

    let middle = '****';

    // 最后的返回值由三部分组成
    return idcard.substring(0, frontLen) + middle + idcard.substring(idcard.length - endLen);
  }

  static privacyEmail(value: string, frontLen: number = 1, endLen: number = 1) {
    let AtIndex = value.search('@');
    if (AtIndex > 0) {
      let nameStr = value.substring(0, AtIndex);
      let atBack = value.substring(AtIndex, value.length);
      if (nameStr.length < frontLen + endLen) {
        return '**' + atBack;
      } else {
        return nameStr.substring(0, frontLen) + '**' + nameStr.substring(nameStr.length - endLen) + atBack;
      }
    } else {
      return value;
    }

    // 最后的返回值由三部分组成
  }

  //数组去重
  static removeDuplicatedArray(array: any[], key: string = 'id') {
    let result: any = [];
    array.forEach((el) => {
      if (!result.some((e) => e[key] == el[key])) {
        result.push(el);
      }
    });
    return result;
  }
}
// 验证手机号码
export function formatPhoneNum(phoneNum) {
  return /^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(phoneNum);
}

//判断字符是否为空
export function isEmptyStr(obj) {
  var regu = "^[ ]+$";
  var re = new RegExp(regu);
  if(typeof obj == "undefined" || obj == null || obj == "" || re.test(obj)){
    return true;
  }else{
    return false;
  }
}
